<template>
  <b-card-code title="Ajouter un dépôt">
    <b-card-text>
      <span>Veuillez saisir les coordonnées de dépôt </span>
    </b-card-text>

    <!-- form -->
    <validation-observer ref="simpleRules">
      <b-form>
        <b-row>
          <b-col md="6">
            <b-form-group
              label="Activation"
              label-for="Activation"
            >
              <b-form-checkbox
                v-model="repository.is_active"
                name="check-button"
                switch
                inline
              />
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group
              label="En location"
              label-for="En location"
            >
              <b-form-checkbox
                v-model="repository.is_under_rent"
                name="check-button"
                switch
                inline
              />
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group
              label="Dépôt"
              label-for="Dépôt"
            >
              <validation-provider
                #default="{ errors }"
                name="Dépôt"
                rules="required"
              >
                <b-form-input
                  v-model="repository.name"
                  :state="errors.length > 0 ? false : null"
                  placeholder="Dépôt"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group
              label="Téléphone"
              label-for="Téléphone"
            >
              <validation-provider
                #default="{ errors }"
                name="phone"
              >
                <b-form-input
                  v-model="repository.phone"
                  :state="errors.length > 0 ? false : null"
                  placeholder="Téléphone"
                  type="number"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group
              label="Email"
              label-for="Email"
            >
              <validation-provider
                #default="{ errors }"
                name="email"
              >
                <b-form-input
                  v-model="repository.email"
                  :state="errors.length > 0 ? false : null"
                  placeholder="Email"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group
              label="Address"
              label-for="Address"
            >
              <validation-provider
                #default="{ errors }"
                name="address"
              >
                <b-form-input
                  v-model="repository.address"
                  :state="errors.length > 0 ? false : null"
                  placeholder="Address"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group
              label="Espace Disponible"
              label-for="Espace Disponible"
            >
              <validation-provider
                #default="{ errors }"
                name="available_space"
              >
                <b-form-input
                  v-model="repository.available_space"
                  :state="errors.length > 0 ? false : null"
                  type="number"
                  placeholder="la surface en m2"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col
            v-if="repository.is_under_rent"
            md="4"
          >
            <b-form-group
              label="Montant du loyer mensuel"
              label-for="Montant du loyer mensuel"
            >
              <validation-provider
                #default="{ errors }"
                name="monthly_rent_amount"
              >
                <b-form-input
                  v-model="repository.monthly_rent_amount"
                  :state="errors.length > 0 ? false : null"
                  type="number"
                  placeholder="Montant du loyer mensuel en TND"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <b-button
              variant="primary"
              type="submit"
              class="mb-1 mb-sm-0 mr-0 mr-sm-1"
              :class="{ 'disabled-cursor': isLoading || !isRepositoryValid }"
              :disabled="isLoading || !isRepositoryValid"
              @click.prevent="addRepository"
            >
              Ajouter
            </b-button>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>
  </b-card-code>
</template>

<script>
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import BCardCode from '@core/components/b-card-code'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BFormInput,
  BFormGroup,
  BForm,
  BRow,
  BCol,
  BButton,
  BCardText,
} from 'bootstrap-vue'
import { required } from '@validations'
import axios from 'axios'

export default {
  components: {
    BCardCode,
    ValidationProvider,
    ValidationObserver,
    BCardText,
    /* BFormTextarea, */
    BFormInput,
    BFormGroup,
    BForm,
    BRow,
    BCol,
    BButton,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,

  },
  data() {
    return {
      repository: {
        name: '',
        phone: '',
        email: '',
        address: '',
        is_active: true,
        is_under_rent: true,
        available_space: 100,
        monthly_rent_amount: 0,
      },
      required,
      isLoading: false,
    }
  },
  computed: {
    isRepositoryValid() {
      return (
        this.repository.name !== ''
      )
    },
  },
  methods: {
    async addRepository() {
      this.$refs.simpleRules.validate().then(async success => {
        if (success) {
          try {
            this.isLoading = true
            await axios
              .post(
                '/api/repositories/create/',
                {
                  name: this.repository.name,
                  phone: this.repository.phone,
                  email: this.repository.email,
                  address: this.repository.address,
                  is_active: this.repository.is_active,
                  is_under_rent: this.repository.is_under_rent,
                  available_space: this.repository.available_space,
                  monthly_rent_amount: this.repository.monthly_rent_amount,
                },
              )
            this.isLoading = false
            setTimeout(() => {
              this.showToast('success', 'top-center', 'Dépôt ajouté avec succés')
            }, 1000)
            this.$router.push('/repositories/')
          } catch (error) {
            setTimeout(() => {
              this.showToast('danger', 'top-center', error.toString())
            }, 1000)
          }
        } else {
          setTimeout(() => {
            this.showToast('danger', 'top-center', 'champ obligatoire ! vérifier votre donnée !')
          }, 1000)
        }
      })
    },

    showToast(variant, position, text) {
      this.$toast(
        {
          component: ToastificationContent,
          props: {
            title: 'Notification',
            icon: 'InfoIcon',
            text,
            variant,
          },
        },
        {
          position,
        },
      )
    },
  },
}
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
