var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-card-code',{attrs:{"title":"Ajouter un dépôt"}},[_c('b-card-text',[_c('span',[_vm._v("Veuillez saisir les coordonnées de dépôt ")])]),_c('validation-observer',{ref:"simpleRules"},[_c('b-form',[_c('b-row',[_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"Activation","label-for":"Activation"}},[_c('b-form-checkbox',{attrs:{"name":"check-button","switch":"","inline":""},model:{value:(_vm.repository.is_active),callback:function ($$v) {_vm.$set(_vm.repository, "is_active", $$v)},expression:"repository.is_active"}})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"En location","label-for":"En location"}},[_c('b-form-checkbox',{attrs:{"name":"check-button","switch":"","inline":""},model:{value:(_vm.repository.is_under_rent),callback:function ($$v) {_vm.$set(_vm.repository, "is_under_rent", $$v)},expression:"repository.is_under_rent"}})],1)],1),_c('b-col',{attrs:{"md":"4"}},[_c('b-form-group',{attrs:{"label":"Dépôt","label-for":"Dépôt"}},[_c('validation-provider',{attrs:{"name":"Dépôt","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false : null,"placeholder":"Dépôt"},model:{value:(_vm.repository.name),callback:function ($$v) {_vm.$set(_vm.repository, "name", $$v)},expression:"repository.name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"4"}},[_c('b-form-group',{attrs:{"label":"Téléphone","label-for":"Téléphone"}},[_c('validation-provider',{attrs:{"name":"phone"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false : null,"placeholder":"Téléphone","type":"number"},model:{value:(_vm.repository.phone),callback:function ($$v) {_vm.$set(_vm.repository, "phone", $$v)},expression:"repository.phone"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"4"}},[_c('b-form-group',{attrs:{"label":"Email","label-for":"Email"}},[_c('validation-provider',{attrs:{"name":"email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false : null,"placeholder":"Email"},model:{value:(_vm.repository.email),callback:function ($$v) {_vm.$set(_vm.repository, "email", $$v)},expression:"repository.email"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"4"}},[_c('b-form-group',{attrs:{"label":"Address","label-for":"Address"}},[_c('validation-provider',{attrs:{"name":"address"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false : null,"placeholder":"Address"},model:{value:(_vm.repository.address),callback:function ($$v) {_vm.$set(_vm.repository, "address", $$v)},expression:"repository.address"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"4"}},[_c('b-form-group',{attrs:{"label":"Espace Disponible","label-for":"Espace Disponible"}},[_c('validation-provider',{attrs:{"name":"available_space"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false : null,"type":"number","placeholder":"la surface en m2"},model:{value:(_vm.repository.available_space),callback:function ($$v) {_vm.$set(_vm.repository, "available_space", $$v)},expression:"repository.available_space"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),(_vm.repository.is_under_rent)?_c('b-col',{attrs:{"md":"4"}},[_c('b-form-group',{attrs:{"label":"Montant du loyer mensuel","label-for":"Montant du loyer mensuel"}},[_c('validation-provider',{attrs:{"name":"monthly_rent_amount"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false : null,"type":"number","placeholder":"Montant du loyer mensuel en TND"},model:{value:(_vm.repository.monthly_rent_amount),callback:function ($$v) {_vm.$set(_vm.repository, "monthly_rent_amount", $$v)},expression:"repository.monthly_rent_amount"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,40491861)})],1)],1):_vm._e(),_c('b-col',{attrs:{"cols":"12"}},[_c('b-button',{staticClass:"mb-1 mb-sm-0 mr-0 mr-sm-1",class:{ 'disabled-cursor': _vm.isLoading || !_vm.isRepositoryValid },attrs:{"variant":"primary","type":"submit","disabled":_vm.isLoading || !_vm.isRepositoryValid},on:{"click":function($event){$event.preventDefault();return _vm.addRepository.apply(null, arguments)}}},[_vm._v(" Ajouter ")])],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }